import React, { useRef, useState, useEffect } from "react"
import { Form, Button } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby";
import InputField from './elements/input'
import SelectField from './elements/select'
import FileField from './elements/file'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import $ from 'jquery/dist/jquery.min.js'
// import { getIpAddress } from "../common/site/functions"
// import { getAllCookie } from "../common/site/cookie";

// import "../../scss/forms.scss";

import axios from "axios"
import * as qs from "query-string"

function ContactForm(props) {

  const [ipAddress, setIpAddress] = useState("");
  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);
  const [selectedInterst, setSelectIntrest] = useState("");
  const [isValid, setIsValid] = useState(true);

  const [SelectOptionVal, setSelectOptionVal] = useState("")

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");
  const [file, setFile] = useState(""); // storing the uploaded file
  // storing the recived file from backend
  const [data, getFile] = useState({ name: "", path: "" });
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();
  const fields = ([
    {
      element: "config",
      formname: "Contact Enquiry",
      error_text: "Highlighted fields are required",
      success_text: "Thank you for your interest. A member of our team will contact you shortly.",
      email_temp_user: "offplan_user",
      email_temp_admin: "offplan_admin",
      email_subject_user: `Thank you for your enquiry`,
      email_subject_admin: "Contact Enquiry",
      email_server_func: "contact",
      event_tracking: "Contact - Enquiry",
      page_url: "/contact"
    },
    {
      grpmd: "6",
      label: "First Name*",
      placeholder: "",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      fieldClass: "",
      labelClass: "content_b-18"
    },
    {
      grpmd: "6",
      label: "Last Name*",
      placeholder: "",
      name: "lastname",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      fieldClass: "",
      labelClass: "content_b-18"
    },
    {
      grpmd: "6",
      label: "Email*",
      placeholder: "",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      class: ""
    },
    {
      grpmd: "6",
      label: "Telephone*",
      placeholder: "",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      fieldClass: "",
      labelClass: "content_b-18"
    },
    {
      grpmd: "12",
      label: "Type of Buyer*",
      placeholder: "Select Type",
      name: "buyer_type",
      type: "select",
      element: "select",
      required: true,
      values: ['Buy to Own', 'Buy to Let'],
      class: "buyer-select",
      labelClass: "content_b-18"
    },
    {
      name: "Submit Enquiry",
      type: "submit",
      element: "button",
      value: "submit",
      step: "col-md-12 text-center",
      class: "btn",
      formclass: ""
    },
    {
      step: "col-md-12",
      text: 'By clicking Submit, you agree to our <a href="/terms-and-conditions/">Terms & Conditions</a> and <a href="/privacy-policy/">Privacy Policy</a>.',
      element: "html",
      class: "apply"
    },
    {
      element: "captcha",
      class: "py-2 captcha-class",
      captchaRef: recaptchaRef
    },
  ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()

  }
  const Selecthandlechange = event => {
    // remove initial empty spaces
    setSelectOptionVal(event.target.value)
    event.target.value = event.target.value.trimStart()

  }



  useEffect(() => {

    $('.samepage-form-link').on('click', function(event){
        event.preventDefault();
        $('html, body').animate({
            scrollTop: $( $.attr(this, 'href') ).offset().top - 100
        }, 500);
    });

    $(document).on('click', 'form.search-wrapper .search-btn, form.search-wrapper .update-btn', function () {
        setTimeout(function () {
            var geturl = window.location.href;
            geturl = geturl.split('for-sale')[1];
            if (geturl.length > 1) {
                var headerHeight = $('header.header').outerHeight();
                if ($(window).width() > 991) {
                    headerHeight = headerHeight + 100;
                }
                $('html, body').animate({
                    scrollTop: $('.search-banner').offset().top - headerHeight
                }, 500);
            }
        }, 500);
    });

    // console.log(window.location.search)

    if (token !== '') {

      const processFromData = async () => {


        formvalues['g-recaptcha-response'] = token;

        const page_url = typeof window !== 'undefined' ? window.location.href : ''


        var gtm_client_id = '';
        var user_agent = '';
        if (typeof window !== 'undefined') {
          user_agent = window.navigator.userAgent
          if (typeof window.ga !== 'undefined') {
            gtm_client_id = window.ga.getAll()[0].get('clientId');

          }
        }

        const salesforce_data = {
          "first_name": formvalues.name, 
          "last_name": formvalues.lastname, 
          "email": formvalues.email, 
          "tele_phone": formvalues.telephone, 
          "buyer_type":formvalues.buyer_type,
          "debug": true 
        };
      
        formvalues['salesforce_data'] = salesforce_data;

        let formData = new FormData();
        formvalues['name'] = formvalues.name;
        formvalues['email_subject_user'] = fields[0].email_subject_user;
        formvalues['email_subject_admin'] = fields[0].email_subject_admin;

        formvalues['referrer'] = page_url;
        formvalues['extra'] = JSON.stringify(formvalues);

        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(async apiRes => {
          window.grecaptcha.reset()
          // lets send mail

          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })
          // .then(res => res.json())
          // setServerResponse(response)


          // const axiosOptions_email = {
          //   url: '/api/server/' + fields[0].email_server_func,
          //   method: "post",
          //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //   data: qs.stringify(formvalues),
          // }

          // axios(axiosOptions_email)
          //   .then(response => {
          //     console.log('mail sent!')
          //   })
          //   .catch(err =>
          //     console.log(err)
          //   );
        });

        const url = typeof window !== 'undefined' ? window.location.href : ''
        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': fields[0].event_tracking,
          'formId': '4',
          'formName': fields[0].event_tracking,
          'formLabel': fields[0].event_tracking,
        });

        setShowerror(false);
        setThankyou(true);
        setTimeout(() => {
          $(".alert-success").show().delay(4000).fadeOut();
        }, 500)
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
      processFromData();


    }

  const search = typeof window !== 'undefined' ? window.location.search : '';

  if (search == "?mortgage" && SelectOptionVal != '') {
    // selected = "Mortgages"
    setSelectOptionVal("Mortgages")
  }

  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
    setShowerror(false);
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })

    }
    else {
      event.preventDefault();
      setShowerror(false);
      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;


      json['files'] = file;
      setFile(file);
      setFormvalues(json);

      recaptchaRef.current.execute();
      // setToken("aaa")
      setValidated(false);

      // reset form
      const form = event.target
      form.reset();

    }
  };

  const url = typeof window !== 'undefined' ? window.location.href : ''
  // var selected = "";
  return (
    <div>
      <div ref={myRef} />



      <Form className="form contact-landing-form" id="contact-form" name={fields[0].formname} method="post" noValidate validated={validated} onSubmit={handleSubmit}>
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="to_email_id" value={props.to_email_id ? props.to_email_id : ''} />
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form_page" value={url} />

        {showerror && <div className="alert alert-danger alert-error">
          <p>{fields[0].error_text}</p>
        </div>}

        {showthankyou && <div className="alert alert-success">
          <p>{fields[0].success_text}</p>
        </div>}
        <div className="row">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  label={field.label}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                />
              );
            }
            if ("file" === field.element) {
              return (
                <FileField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  label={field.label}
                  type={field.type}
                  fieldClass={field.fieldClass}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  key={`${field.element}~${index}`}
                  accept={field.accept}
                  // handlechange={handleAttachment}
                  required={field.required}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  id={field.id}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={Selecthandlechange}
                  alias={props.alias}
                  formtype={props.formtype}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  label={field.label}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  step={field.step}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <Form.Group>
                  <ButtonField
                    name={field.name}
                    fieldClass={field.class}
                    step={field.step}
                    formclass={field.formclass}
                    type={field.type}
                    value={field.value}
                    key={`${field.element}~${index}`}
                  />
                </Form.Group>
              );
            }
          })
          }
        </div>
      </Form>
    </div>
  );
}


const ContactFormPage = (props) => (
  <ContactForm alias={props.alias} formtype={props.formtype} classone={props.classone} to_email_id={props.to_email_id} title={props.title} />
)

export default ContactFormPage
