
import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import parse from "html-react-parser"
import { getSrc } from "gatsby-plugin-image"
import Review from "../../images/google-reviews.svg"
import bannerImg from "../../images/banner-image.svg"
import './Banner.scss';
import Tabbing from "../Tabbing/Tabbing";
import BannerSelect from "../BannerSelect/BannerSelect";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import GenerateLink from "../../common/link/generate-link"
import ReviewBadge from "../ReviewBadge/ReviewBadge";
import OffplanForm from "../forms/offplan-form";

const LandingBannerOffplan = (props) => {
    const [scroll, setScroll] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 60)
        })
    }, [])

    const src = getSrc(props?.banner_image?.url_sharp?.childImageSharp)

    return (
        <>
            <section className="offplan_banner banner" style={{ backgroundImage: `url(${src})` }}>
            <div className='bg-overlay'></div>
                <Container>
                    <Row className="banner-leftside">
                        <Col lg={6} className="banner-col-left">
                            <div className="banner-content-wrapper">
                                <div className="rtl-wrapper">
                                    <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
                                        {parse(props.banner_content)}
                                    </AnimationOnScroll>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} className="banner-col-right">
                            <div className="banner-form-wrapper" id="enquiry-form-section">
                                <div className="form-full-image-page">
                                    <section className="banner">
                                        <div className="bg-overlay">
                                            <div className="content">
                                                <div className="contact-form">
                                                    <div className="head">
                                                        {parse(props.banner_right_content)}
                                                    </div>
                                                    <OffplanForm />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
        </>
    )
}

export default LandingBannerOffplan
